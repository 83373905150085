import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickSidebarComponent } from './layout/quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './layout/scroll-top/scroll-top.component';
import { TooltipsComponent } from './layout/tooltips/tooltips.component';
import { ListSettingsComponent } from './layout/quick-sidebar/list-settings/list-settings.component';
import { MessengerModule } from './layout/quick-sidebar/messenger/messenger.module';
import { CoreModule } from '../../core/core.module';
import { ListTimelineModule } from './layout/quick-sidebar/list-timeline/list-timeline.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
import { SpinnerButtonModule } from './content/general/spinner-button/spinner-button.module';
import { BlogComponent } from './content/widgets/general/blog/blog.component';
import { FinanceStatsComponent } from './content/widgets/general/finance-stats/finance-stats.component';
import { PackagesComponent } from './content/widgets/general/packages/packages.component';
import { TasksComponent } from './content/widgets/general/tasks/tasks.component';
import { SupportTicketsComponent } from './content/widgets/general/support-tickets/support-tickets.component';
import { RecentActivitiesComponent } from './content/widgets/general/recent-activities/recent-activities.component';
import { RecentNotificationsComponent } from './content/widgets/general/recent-notifications/recent-notifications.component';
import { AuditLogComponent } from './content/widgets/general/audit-log/audit-log.component';
import { BestSellerComponent } from './content/widgets/general/best-seller/best-seller.component';
import { AuthorProfitComponent } from './content/widgets/general/author-profit/author-profit.component';
import { DataTableComponent } from './content/widgets/general/data-table/data-table.component';
import { WidgetChartsModule } from './content/widgets/charts/widget-charts.module';
import { StatComponent } from './content/widgets/general/stat/stat.component';
//PrimeNG
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { PapaParseModule } from 'ngx-papaparse';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CsvModule } from '@ctrl/ngx-csv';
import { PickListModule } from 'primeng/picklist';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { ChartModule } from 'primeng/chart';
import { GrowlModule } from 'primeng/growl';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { EditorModule } from "primeng/editor";
import { SidebarModule} from 'primeng/sidebar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { Slim } from './content/general/slim/slim.angular2';
import { TranslateInputComponent } from './content/general/translate-input/translateInput.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PaginationComponent } from '../pages/components/ngbootstrap/pagination/pagination.component';
import { PaginationCondorComponent } from './content/general/pagination/pagination.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterComponent } from './content/general/filter/filter.component';
/* import {BrowserModule} from '@angular/platform-browser'; */
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';

@NgModule({
	declarations: [
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		AuditLogComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,
		Slim,
		TranslateInputComponent,
		PaginationCondorComponent,
		FilterComponent,
	],
	exports: [
		ReactiveFormsModule,
		FormsModule,
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		AuditLogComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,
		Slim,
		TranslateInputComponent,
		PaginationCondorComponent,
		FilterComponent,

/* 		BrowserModule, */
		PortletModule,
		SpinnerButtonModule,
		SnotifyModule,
		PapaParseModule,
		SelectButtonModule,
		ProgressSpinnerModule,
		CsvModule,
		AutoCompleteModule,
		CheckboxModule,
		PickListModule,
		MultiSelectModule,
		ChipsModule,
		ChartModule,
		GrowlModule,
		DialogModule,
		DropdownModule,
		FileUploadModule,
		EditorModule,
		SidebarModule,
		CalendarModule,
		InputSwitchModule,
		KeyFilterModule,
/* 		BrowserAnimationsModule, */
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
/* 		BrowserModule, */
		RouterModule,
		NgbModule,
		PerfectScrollbarModule,
		MessengerModule,
		ListTimelineModule,
		CoreModule,
		PortletModule,
		SpinnerButtonModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatPaginatorModule,
		MatInputModule,
		MatSelectModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatIconModule,
		MatTooltipModule,
		WidgetChartsModule,
		TranslateModule,
		//Primeng
		SnotifyModule,
		PapaParseModule,
		SelectButtonModule,
		ProgressSpinnerModule,
		CsvModule,
		AutoCompleteModule,
		CheckboxModule,
		PickListModule,
		MultiSelectModule,
		ChipsModule,
		ChartModule,
		GrowlModule,
		DialogModule,
		DropdownModule,
		FileUploadModule,
		EditorModule,
		SidebarModule,
		CalendarModule,
		InputSwitchModule,
		KeyFilterModule,
/* 		BrowserAnimationsModule, */
	],
	providers:[
		SnotifyService,
	]
})
export class PartialsModule {}
