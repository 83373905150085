import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';


@Injectable()
export class ApiService{
  api_url:string = environment.urlApi+environment.api;
  token:string;
  constructor(
    private http: HttpClient,
		private router: Router,
    private authService: AuthenticationService,
  ) {
    this.authService.getAccessToken().subscribe(token => {
        this.token = token;
    });
    this.authService.onCredentialUpdated$.subscribe(data =>{
        if(data.access_token){
            this.token = data.access_token;
        }
    })
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'authorization': `Bearer ${this.token}`
    };

    return new HttpHeaders(headersConfig);
  }


  formatErrors(error: any) {
    console.log(error)
    if(error.status === 401){
      this.authService.logout(true);
    }
    return Observable.throw(error.error)
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let data = this.http.get(`${this.api_url}${path}`, { headers: this.setHeaders(), params }).pipe(
      catchError(this.formatErrors.bind(this))
    )
    return data;
  }

  put(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.put(
      `${this.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors.bind(this))
    )
  }

  post(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${this.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors.bind(this))
    )
  }

  delete(path , params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(
      `${this.api_url}${path}`,
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors.bind(this))
    )
  }
}
