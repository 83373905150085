import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from '../../../../../core/services/utils.service';
import { DataSupplyService } from '../../../../../core/services/data_supply.service';
import { TranslationService } from '../../../../../core/services/translation.service';

@Component({
  selector: 'translateInput',
  templateUrl: './translateInput.component.html',
  styleUrls: ['./translateInput.component.css']
})
export class TranslateInputComponent implements OnInit {

  languages: any;
  selectedLanguage:any
  value: any = [];
  @Input() left: boolean = false;
  @Input() input: boolean = false;
  @Input() dropdown: boolean = false;
  @Input() required: boolean = false;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Output() triggerValue = new EventEmitter();
  @Input() form: FormGroup;
  @Input() validationName: string;
  @Input() loadValue: any = [];
  count: number = 0 

  constructor(
              private router: Router,
              private utils : UtilsService,
              private translate: TranslateService,
              private translation: TranslationService,
              private dataSupplyService : DataSupplyService,
              private fb: FormBuilder,
            ) {

             }

  ngOnInit() {
    this.dataSupplyService.currentLanguages.subscribe(languages => {
      this.languages = languages; 
      this.formatValue()
    })
  }

  formatValue(){
    this.selectedLanguage = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang
    this.translation.getSelectedLanguage().subscribe(
      lang => {
        this.selectedLanguage = lang;
        if(this.count < 1 && this.languages){
          console.log(this.loadValue)
          this.load();
          this.count += 1 
        }
      }
    )
  }

  changeInput(e = null, iso){
    if(e){
      this.value[iso] = e.htmlValue
    }
    let value = []
    this.languages.forEach(language => {
      if(this.value[language.iso]){
        value.push({iso_code: language.iso, value:this.value[language.iso]})
      }
    })
    this.triggerValue.emit(value);
  }

  load(){
    if(!this.loadValue){
      this.languages.forEach((l) => this.value[l.iso] = '')
    }else if(typeof this.loadValue === 'string'){
      this.languages.forEach((l) => this.value[l.iso] = this.loadValue)
    }else{
      this.loadValue.forEach((lv) => this.value[lv.iso_code] = lv.value)
      this.languages.forEach((l) => this.value[l.iso] ? this.value[l.iso] = this.value[l.iso] :  this.value[l.iso] = '')
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loadValue'] && this.loadValue) {
    }else{
    }
  }
}