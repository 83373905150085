import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FeaturesResolver implements Resolve<Object> {
    constructor(
        private apiService: ApiService,
        private router: Router,
    ) {}

    resolve(
    ): Observable<any> {
        console.log('FeaturesResolver')
        return this.apiService.get('/features')
    }
}

@Injectable()
export class FeatureValuesResolver implements Resolve<Object> {
    constructor(
        private apiService: ApiService,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
    ): Observable<any> {
        console.log('FeatureValuesResolver')
        return this.apiService.get('/featureValues')
    }
}
