import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../../../../core/services/utils.service';

@Component({
  selector: 'paginationCondor',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationCondorComponent implements OnInit {

  public name = null;
  public loggedIn:boolean;
  public isAdmin:boolean;
  cants =  [{name: 'items10', value:'10'},
            {name: 'items20', value:'20'},
            {name: 'items30', value:'30'}]
  cant = {name: 'items10', value:'10'}

  @Input() page: number;
  @Input() pages: number = 1;
  @Output() triggerPage = new EventEmitter();
  @Output() triggerCant = new EventEmitter();
    
  constructor(
    public utils: UtilsService,
  ) {}

  ngOnInit() {
    }

    getPage(page){
      if(page > this.pages){
        this.page = this.pages;
        return;
      }else if(page < 1){
        this.page = 1;
        return;
      }
      this.triggerPage.emit(page);
    }

    changeCant(){
      this.triggerCant.emit(this.cant.value)
    }


}
