import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { throwError } from 'rxjs';


@Injectable()
export class PrestashopService{
  shop_url:string = 'http://esfashion.ecomm360.net/'
  shop_key:string = '12QZXJHXC1BUW6DN9ADTPGLIJIC22DSY'
  token:string;
  constructor(
    private http: HttpClient,
  ) {
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }


  private formatErrors(error: any) {
    console.log(error)
    return throwError(error)
/*     return Observable.throw(error.error) */
  }

  get(path: string, params: object = {}): Observable<any> {
    let httpParams = {'ws_key': this.shop_key, ...params};
    console.log(httpParams)
    let data = this.http.get(`${this.shop_url}${path}`, { headers: this.setHeaders(), params: httpParams}).pipe(
      catchError(this.formatErrors)
    )
    return data;
  }
  
  getPS(resource, id, display, filters){
    let filt = ""
    filters.forEach(filter => {
        let str = `&filter[${filter.key}]=${filter.value}`;
        filt += str;
    });
   return this.http.get(`${this.shop_url}api/${resource}/${id}?display=${display}&io_format=JSON&ws_key=${this.shop_key+filt}`,{});
}

/*   put(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.put(
      `${this.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors)
    )
  }

  post(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${this.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors)
    )
  }

  delete(path , params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(
      `${this.api_url}${path}`,
      { headers: this.setHeaders(), params }
    ).pipe(
      catchError(this.formatErrors)
    )
  } */
}
