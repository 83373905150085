import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class DataSupplyService {

  private messageSource = new BehaviorSubject('default message');
  private shops = new BehaviorSubject([]);
  private languages = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();
  currentShops = this.shops.asObservable();
  currentLanguages = this.languages.asObservable();
  

  constructor(
    private apiService: ApiService,
  ) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeShops(shops: any){
    this.shops.next(shops)
  }

  changeLanguages(languages: any){
    this.languages.next(languages)
  }

  loadShops(){
    this.apiService.get('/shops').subscribe(
      data => {
        this.changeShops(data['shops'])
      }
    )
  }

  loadLanguages(){
    this.apiService.get('/languages').subscribe(
      data => {
        let languages = data['languages'];
        languages = languages.sort(function(a,b){
          if(a.position < b.position)
            return -1 
          if(a.position > b.position)
            return 1
          return 0
        })
        console.log(languages)
        this.changeLanguages(languages)
      }
    )
  }
}