import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProductsResolver implements Resolve<Object> {
    constructor(
        private apiService: ApiService,
        private router: Router,
    ) {}

    resolve(
    ): Observable<any> {
		let params = new HttpParams()
					.set('page', '1')
					.set('cant', '10')
		return this.apiService.get('/products', params)
    }
}

@Injectable()
export class ProductResolver implements Resolve<Object> {
    constructor(
        private apiService: ApiService,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
    ): Observable<any> {

        if(route.queryParams['id'] && route.queryParams['id'] != 'add'){
            return this.apiService.get(`/products/${route.queryParams['id']}`)
        }else{
            if(route.params['id'] && route.params['id'] != 'add'){
                return this.apiService.get(`/products/${route.params['id']}`)
            }
        }
    }
}
