import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DataSupplyService } from './data_supply.service';
import { TranslationService } from './translation.service';

@Injectable()
export class UtilsService {
	languages: any = []
	lang: string = ''
	constructor(
		private translationService: TranslationService,
		private translateService: TranslateService,
		private dataSupplyService: DataSupplyService,
	) {
		this.translationService.getSelectedLanguage().subscribe(lang => {
			if (lang) {
				this.lang = lang
			}
		});
		this.dataSupplyService.currentLanguages.subscribe(data => this.languages = data)
	}

	/**
	 * Build url parameters key and value pairs from array or object
	 * @param obj
	 */
	urlParam(obj: any): string {
		return Object.keys(obj)
			.map(k => k + '=' + encodeURIComponent(obj[k]))
			.join('&');
	}

	/**
	 * Simple object check.
	 * @param item
	 * @returns {boolean}
	 */
	isObject(item) {
		return item && typeof item === 'object' && !Array.isArray(item);
	}

	/**
	 * Deep merge two objects.
	 * @param target
	 * @param ...sources
	 * @see https://stackoverflow.com/a/34749873/1316921
	 */
	mergeDeep(target, ...sources) {
		if (!sources.length) {
			return target;
		}
		const source = sources.shift();

		if (this.isObject(target) && this.isObject(source)) {
			for (const key in source) {
				if (this.isObject(source[key])) {
					if (!target[key]) {
						Object.assign(target, { [key]: {} });
					}
					this.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return this.mergeDeep(target, ...sources);
	}

	getPath(obj, val, path?) {
		path = path || '';
		let fullpath = '';
		for (const b in obj) {
			if (obj[b] === val) {
				return path + '/' + b;
			} else if (typeof obj[b] === 'object') {
				fullpath =
					this.getPath(obj[b], val, path + '/' + b) || fullpath;
			}
		}
		return fullpath;
	}

	getFindHTTPParams(queryParams): HttpParams {
		const params = new HttpParams()
			.set('lastNamefilter', queryParams.filter)
			.set('sortOrder', queryParams.sortOrder)
			.set('sortField', queryParams.sortField)
			.set('pageNumber', queryParams.pageNumber.toString())
			.set('pageSize', queryParams.pageSize.toString());

		return params;
	}

	getHTTPHeader() {
		return {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
	}


	/**
	 * Recibe un array de items y una propiedad que por defecto es name luego
	 * llama @processReturnExposeName con la propiedad y lo guarda en exposeName
	 * y finalmente devuelve los items con la propiedad processada en exposeName.
	 * 
	 * @param items array de Objetos
	 * @param propertyAttach string es la propiedad que debe resolver en exposeName
	 */ 
	processExposeName(items, propertyAttach='name'){
		items.forEach(item => {
			if(item.hasOwnProperty(propertyAttach)){
				item['exposeName'] = this.processReturnExposeName(item[propertyAttach])
			}
		});
		return items
	}

	/**
	 * Admite como entrada los nombres de prestashop con o sin traducciones y 
	 * devuelve el nombre correcto dependiedo del idioma. En caso de no tener 
	 * traducciones devolvera el nombre. 
	 * 
	 * @param name Recibe el nombre o el array de nombre de prestashop, 
	 * @return string Devuelve el nombre correcto, dependiendo de el idioma elegido en angular 
	 */
	processReturnExposeName(name: string | any){
		if(name){
			if(typeof name == 'string'){
				return name
			}else if (typeof name == 'object'){
				let retrieve = name.find(nameIdioma => {
					if(nameIdioma && nameIdioma.hasOwnProperty('iso_code') && nameIdioma.iso_code === this.lang){
						return nameIdioma;
					}
				})
				if(retrieve){
					return retrieve.value || 'Not found'
				}
				if(name && name[0] && name[0].value && typeof name[0].value == 'string'){//not implemented multilang but change only number of array
					return name[0].value;
				}else{
					return 'Not fouund';
				}
			}
		}
	}
	
	processReturnName(item,index=true){
		if(typeof item == 'string'){
			return item;
		}
		else if (typeof item == 'object'){
			let retrieve = item.find(property => {
				if((property.hasOwnProperty('iso_code') && property.iso_code === this.lang) || (property.hasOwnProperty('iso') && property.iso === this.lang)){
					return property.value;
				}
			});
			if(retrieve){
				return retrieve.value
			}
			if(index){
				let language = this.languages.find(l => l.iso == this.lang)
				if(language){
					return item[language.position-1].value;
				}
			}
		}
	}
	
	addAll(items, type){
		items.unshift({id:0,name:`All ${type}`});
		return this.processExposeName(items)
	}
	
	
	getAttributeValueOfCombination(c:any, attributes: any[], attribute_values: any[]){
		let name = ""
		if(!c && !c.associations && !c.associations.product_option_values){
			return 'Not found'
		}
		c.associations.product_option_values.forEach(association_id => {
			if(!attribute_values || !attributes){
				return 'Not found';
			}
			let value = attribute_values.find((val) =>{
				return val.id == association_id
			})
			if(!value){
				name = 'Not found'
				return
			}
			let attribute = attributes.find((attr) => {
				return attr.id == value.id_attribute_group
			})
			if(!attribute){
				name = 'Not found'
				return
			}
			if(!name){
				name = `${this.processReturnExposeName(attribute.public_name)}: ${this.processReturnExposeName(value.name)}`;
			}else{
				name += `${this.processReturnExposeName(attribute.public_name)}: ${this.processReturnExposeName(value.name)}`;
			}
		});
		return name;
	}
		  
	findId(valueId:any, whereFind, propertyName='id'){
		return whereFind.find(item => {
			return item[propertyName] == valueId;
		})
	}

	getProperty(item, propertyName){
		if(item && item.hasOwnProperty(propertyName)){
			return this.processReturnExposeName(item[propertyName])
		}else{
			return 'Not found'
		}
	}
	
	numbersToArray(n: number): any[] {
		return Array(n);
	}
}


export function isInteger(value: any): value is number {
	return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}


export function isString(value: any): value is string {
	return typeof value === 'string';
}
