import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { trigger } from '@angular/animations';
import { UtilsService } from '../../../../../core/services/utils.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  public name = null;
  public loggedIn:boolean;
  public isAdmin:boolean;
  cants =  [{name:'10 items', value:'10'},
            {name:'20 items', value:'20'},
            {name:'30 items', value:'30'}]
  cant = {name:'10 items', value:'10'}
  objFilter:any={};
  objSelect:any={};

/*   filters = [
    {type: 'header'},
    {type: 'active'},
    {name:'empresa', type: 'input', placeholder: 'Buscar por empresa'},
    {name:'seguimiento', type: 'input', placeholder: 'Buscar por seguimiento'},
    {name:'internacional', type: 'toggle'},
    {name:'contenedor', type: 'toggle'},
    {type: 'empty'},
    {type: 'empty'},
    {type: 'empty'},
  ] */

  @Input() filters: any[] = [
    {name:'sku', type: 'input', placeholder: 'Buscar por referencia'},
  ];
  @Input() page: number;
  @Input() pages: number = 1;
  @Output() triggerPage = new EventEmitter();
  @Output() triggerCant = new EventEmitter();
  @Output() triggerActive = new EventEmitter();
  @Output() triggerFilter = new EventEmitter();
  @Input() active: boolean = false;
  selectedSwitchs: string[] = [];
  selectedFilter: string = 'sku';
  valueFilter: string = '';
    
  constructor(
              private router: Router, 
              private utils : UtilsService,
              private translate: TranslateService,
            ) { }

  ngOnInit() {
    }
    emitChangeActive(){
      this.triggerActive.emit(this.active)
      if(this.active){
        this.emitFilter()
      }else{
        this.triggerFilter.emit({})
      }
    }
    ngOnChanges(changes: SimpleChanges) {
    if (changes['filters']) {
      let filtersSelectDefault = this.filters.filter((filter) => filter.type === 'selectSomething' && filter.default)
      filtersSelectDefault.forEach((filter) => {
        this.objSelect[filter.name] = filter.default
        this.selectTrigger(filter.name, filter.id)
      })
    }
  }

    emitFilter(){
      console.log(this.selectedFilter)
      console.log(this.valueFilter)
      if(this.valueFilter && this.valueFilter != '' && this.selectedFilter){
        this.triggerFilter.emit({[this.selectedFilter]: this.valueFilter})
      }else{
        this.triggerFilter.emit({})
      }
/*       let cpObjFiltros = {...this.objFilter}
      let filtrosSwitch = this.filters.filter((f) => f.active && f.active === 'true'); */
/*       filtrosSwitch = filtrosSwitch.filter((f) => {
        let disabled = this.selectedSwitchs.find((fS) => fS === f.name)
        console.log(disabled)
        if(!disabled){
          if(!this.objFilter[f.name]){
            //no existe por tanto lo crea como falso
            cpObjFiltros[f.name] = 'false';
          }
        }else{
          cpObjFiltros[f.name] = '';
        }
      }) */


/*       console.log(cpObjFiltros)
      if(this.active){
        this.triggerFilter.emit(cpObjFiltros)
      } */
    }

    selectTrigger(name, id){
      if(this.objSelect[name] && (this.objSelect[name][id] || this.objSelect[name][id] === 0))
        this.objFilter[name] = this.objSelect[name][id]
      this.emitFilter()
    }

}
